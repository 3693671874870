import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Faktory invazivnosti a invazibility" />
    <h1>Faktory invazivnosti a invazibility</h1>


    <p>Faktory invazivnosti druhů a invazibility společenstev jsou důležitou souč&aacute;st&iacute; studia <Link to="/biogeografie/invazni-ekologie/">invazn&iacute; ekologie</Link>. Zat&iacute;mco pojem invazivnost se vztahuje k druhům a jejich schopnostem invadovat společenstva, pojem invazibilita se vztahuje ke společenstvům a jejich n&aacute;chylnosti k invaz&iacute;m nepůvodn&iacute;ch druhů.</p>
<hr />
    <h2>Faktory ovlivňuj&iacute;c&iacute; invazivnost druhů</h2>
    <p>Je mnoho faktorů invazivnosti, tedy faktorů ovlivňuj&iacute;c&iacute; schopnost druhu invadovat ciz&iacute; společenstva, zejm&eacute;na jde o:</p>
    <ul>
    <li><strong>čas od introdukce</strong></li>
    <li><strong>nepř&iacute;buznost druhů</strong></li>
    <li><strong>environment&aacute;ln&iacute; filtrov&aacute;n&iacute;</strong></li>
    <li><strong>nepř&iacute;tomnost přirozen&yacute;ch nepř&aacute;tel</strong></li>
    <li><strong>evoluce vět&scaron;&iacute; konkurenčn&iacute; schopnosti</strong></li>
    <li><strong>evolučn&iacute; nevyrovnanost</strong></li>
    </ul>
    <h3>Čas od introdukce</h3>
    <p>Čas od introdukce je důležit&yacute;m faktorem ovlivňuj&iacute;c&iacute; invazivnost druhu. Č&iacute;m d&eacute;le je totiž dan&yacute; druh zavlečen&yacute; druh v &uacute;zem&iacute;, t&iacute;m v&iacute;ce se zvy&scaron;uje &scaron;ance, že se stane invazivn&iacute;m.</p>
    <h3>Nepř&iacute;buznost druhů</h3>
    <p>Již Darwin vznesl my&scaron;lenku, že zavlečen&eacute; druhy sn&aacute;ze naturalizuj&iacute; ve společenstvech nepř&iacute;buzn&yacute;ch druhů (tj. anglicky <em>limiting similarity</em>). Tato my&scaron;lenka je označov&aacute;na jako <strong>Darwinova naturalizačn&iacute; hypot&eacute;za</strong>.</p>
    <p>Hypot&eacute;za stoj&iacute; na předpokladu, že nepř&iacute;buzn&eacute; druhy maj&iacute; odli&scaron;n&eacute; vlastnosti a obsazuj&iacute; tak jin&eacute; niky, proto tak zavlečen&eacute;mu druhu nekonkuruj&iacute;. Nav&iacute;c nemus&iacute; čelit stejn&yacute;m pred&aacute;torům a patogenům.</p>
    <p>Naturalizačn&iacute; hypot&eacute;za byla mnohokr&aacute;t testov&aacute;na s odli&scaron;n&yacute;mi v&yacute;sledky. Zd&aacute; se, že plat&iacute; sp&iacute;&scaron; na mal&yacute;ch ploch&aacute;ch (ostrovy), kde se může uplatnit př&iacute;m&aacute; konkurence. Na vět&scaron;&iacute;ch m&aacute; pak př&iacute;buznost sp&iacute;&scaron;e v&yacute;hodu, protože zaji&scaron;ťuje preadaptaci druhu na nov&eacute; prostřed&iacute;.</p>
    <h3>Environment&aacute;ln&iacute; filtrov&aacute;n&iacute;</h3>
    <p>Environment&aacute;ln&iacute; filtrov&aacute;n&iacute; je efekt, jehož důsledkem je jist&aacute; podobnost invazivn&iacute;ho druhu s původn&iacute;mi v&yacute;hodn&aacute;, a to kvůli preadaptaci na dan&eacute; životn&iacute; prostřed&iacute;. Preadaptace je dan&aacute; konzervatismem nik, tedy podobn&yacute;mi ekologick&yacute;mi n&aacute;roky zděděn&yacute;ch po společn&yacute;ch předc&iacute;ch.</p>
    <p>Zd&aacute; se, že v&yacute;hody plynouc&iacute; z preadaptace na nov&eacute; prostřed&iacute; převažuj&iacute; nad nev&yacute;hodami plynouc&iacute;mi z předpokl&aacute;dan&eacute; intenzivněj&scaron;&iacute; kompetice se z&aacute;stupci stejn&eacute;ho druhu. To je v&scaron;ak platn&eacute; sp&iacute;&scaron;e pro vět&scaron;&iacute; &uacute;zem&iacute;.</p>
    <h3>Nepř&iacute;tomnost přirozen&yacute;ch nepř&aacute;tel</h3>
    <p>Nepř&iacute;tomnost přirozen&yacute;ch nepř&aacute;tel je podle hypot&eacute;zy &uacute;niku před nepř&aacute;teli (anglicky <em>Enemy Release Hypothesis</em>, zkratka <em>ERH</em>) důležit&yacute;m faktorem invazivnosti druhů. Zavlečen&eacute; druhy se podle n&iacute; &scaron;&iacute;ř&iacute; v nov&eacute;m are&aacute;lu rychle, protože jsou osvobozeny od specializovan&yacute;ch nepř&aacute;tel, a to ať už parazitů či pred&aacute;torů, kteř&iacute; se s nimi vyvinuli společně během evoluce.</p>
    <h3>Evoluce vět&scaron;&iacute; konkurenčn&iacute; schopnosti</h3>
    <p>Evoluce vět&scaron;&iacute; konkurenčn&iacute; schopnosti je hypot&eacute;za (anglicky <em>Evolution of Increased Competitive Ability</em>, zkratka <em>EICA</em>), kter&aacute; předpokl&aacute;d&aacute; evolučn&iacute; změny po zavlečen&iacute; druhu na nov&eacute; &uacute;zem&iacute;, kter&aacute; jim umožn&iacute; přesunout energii nutnou pro obranu před nepř&aacute;teli do pos&iacute;len&iacute; vlastnost&iacute; v&yacute;hodn&yacute;ch pro konkurenci. V&yacute;sledkem může b&yacute;t rychlej&scaron;&iacute; či mohutněj&scaron;&iacute; růst, u rostlin se může nově vyskytnout <Link to="/ekologie-zivotni-prostredi/chemicky-boj-alelopatie/">alelopatie</Link>.</p>
    <p>Hypot&eacute;za EICA je založena na pozorov&aacute;n&iacute;, že zavlečen&yacute; druh se zpravidla po zavlečen&iacute; dlouho ne&scaron;&iacute;ř&iacute; a je v tzv. lag f&aacute;zi. Invaze pak zač&iacute;n&aacute; často n&aacute;hle po del&scaron;&iacute; době př&iacute;tomnosti druhu v nov&eacute;m &uacute;zem&iacute;. Nav&iacute;c, invazn&iacute; rostliny maj&iacute; často vět&scaron;&iacute; vzrůst v sekund&aacute;rn&iacute;m are&aacute;lu než ve sv&eacute;m původn&iacute;m.</p>
    <h3>Evolučn&iacute; nevyrovnanost</h3>
    <p>Evolučn&iacute; nevyrovnanost je podle anglicky nazvan&eacute; hypot&eacute;zy <em>Evolutionary Imbalance Hypothesis </em>(zkratka <em>EIH</em>) dal&scaron;&iacute;m důležit&yacute;m faktorem invazivnosti druhů.</p>
    <p>EIH ř&iacute;k&aacute;, že &uacute;spě&scaron;n&eacute; nepůvodn&iacute; druhy často přich&aacute;z&iacute; z regionů a biotopů s velk&yacute;m genetick&yacute;m potenci&aacute;lem (mnoho nez&aacute;visl&yacute;ch lini&iacute; druhů s velk&yacute;mi populacemi), kde byly po mnoho generac&iacute; vystaveny m&iacute;stn&iacute;m podm&iacute;nk&aacute;m prostřed&iacute; a siln&eacute; konkurenci jin&yacute;ch lini&iacute;. Takov&eacute; druhy maj&iacute; pak lep&scaron;&iacute; invazivnost, protože maj&iacute; lep&scaron;&iacute; evolučn&iacute; adaptace na různ&eacute; druhy kompetice.</p>
    <p>Podle hypot&eacute;zy EIH pak lze vykreslit pravděpodobnost invazn&iacute;ho chov&aacute;n&iacute; druhu jako funkci maxim&aacute;ln&iacute; fylogenetick&eacute; diverzity regionu, ze kter&eacute;ho druh poch&aacute;z&iacute;.</p>
<hr />
    <h2>Faktory ovlivňuj&iacute;c&iacute; invazibilitu společenstev</h2>
    <p>Je mnoho faktorů invazibility, tedy faktorů ovlivňuj&iacute;c&iacute; rezistenci společenstva vůči invaz&iacute;m nepůvodn&iacute;ch druhů, zejm&eacute;na jde o:</p>
    <ul>
    <li><strong>druhov&aacute; bohatost</strong></li>
    <li><strong>fluktuace dostupnosti zdrojů</strong></li>
    <li><strong>př&iacute;sun diaspor nepůvodn&iacute;ch druhů</strong></li>
    <li><strong>předchoz&iacute; invaze (invasional meltdown)</strong></li>
    </ul>
    <h3>Druhov&aacute; bohatost</h3>
    <p>Druhov&aacute; bohatost je dle teorie biotick&eacute; rezistence (anglicky <em>Biotic Resistence Hypothesis</em>) je velmi důležit&yacute;m faktorem pro invazibilitu společenstev. Druhově chud&aacute; společenstva jsou invadov&aacute;na v&iacute;ce než společenstva druhově bohat&aacute;, protože je v nich v&iacute;ce voln&yacute;ch nik a ty jsou obsazov&aacute;ny nepůvodn&iacute;mi druhy.</p>
    <h3>Fluktuace dostupnosti zdrojů</h3>
    <p>Fluktuace dostupnosti zdrojů silně ovlivňuje invazibilitu dan&eacute;ho společenstva. Ta roste s množstv&iacute;m voln&yacute;ch zdrojů, kter&eacute; m&aacute; společenstvo k dispozici.</p>
    <p>Kl&iacute;čov&eacute; je pro vysokou invazibilitu kol&iacute;s&aacute;n&iacute; zdrojů, protože pokud jsou stabiln&iacute; a jejich množstv&iacute; nekol&iacute;s&aacute;, zdroje jsou plynule spotřebov&aacute;v&aacute;ny původn&iacute;mi druhy. K n&aacute;růstu voln&yacute;ch zdrojů doch&aacute;z&iacute; n&aacute;hl&yacute;m př&iacute;sunem z vněj&scaron;ku (např. hnojen&iacute;m, zavlažov&aacute;n&iacute;m) či zmen&scaron;en&iacute;m čerp&aacute;n&iacute; (např. disturbnace, kter&aacute; odstran&iacute; st&aacute;vaj&iacute;c&iacute; vegetaci).</p>
    <h3>Př&iacute;sun diaspor</h3>
    <p>Vět&scaron;&iacute; př&iacute;sun diaspor nepůvodn&iacute;ch druhů zvy&scaron;uje m&iacute;ru invadovanosti společenstva. Č&iacute;m v&iacute;ce je společenstvo odoln&eacute;, t&iacute;m v&iacute;ce diaspor je třeba, aby do&scaron;lo k invazi.</p>
    <h3>Předchoz&iacute; invaze</h3>
    <p>Předchoz&iacute; invaze (anglicky <em>invasional meltdown</em>) nepůvodn&iacute;ch druhů podporuj&iacute; dal&scaron;&iacute; invaze prostřednictv&iacute;m mutualistick&yacute;ch interakc&iacute;, č&iacute;mž se zved&aacute; invazibilita společenstva.</p>

    <hr />
    <ImgCon><Biogeo /><div>
    <h2>Studijn&iacute; materi&aacute;ly Biogeografie</h2>
    <p>T&eacute;ma <strong>Faktory invazivnosti a invazibility</strong> je souč&aacute;st&iacute; <Link to="/biogeografie/">studijn&iacute;ch materi&aacute;lů Biogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předch&aacute;zej&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) či n&aacute;sleduj&iacute;c&iacute; (3. tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/biogeografie/"><button>Rozcestník biogeografie</button></Link>
    <Link to="/biogeografie/invazni-ekologie/invazni-procesy-ve-svete-a-cr/"><button className="inv">&larr; Invaze, zavlečené druhy...</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
